import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export type LogReportHeaderProps = {
    onClose?: () => void;
};

export const LogReportHeader = ({ onClose }: LogReportHeaderProps) => (
    <Box component={Grid} container alignItems="center" sx={{ mb: '12px' }}>
        <Grid item xs>
            <Typography variant="h6" fontWeight={500} fontSize="16px" color="secondary" textTransform="capitalize">
                <FormattedMessage id="fullHistory" />
            </Typography>
        </Grid>
        {onClose && (
            <Grid item xs="auto">
                <IconButton
                    onClick={onClose}
                    size="medium"
                    sx={(theme) => ({
                        color: theme.palette.primary[400],
                        backgroundColor: '#FFF',
                        fontWeight: 600,
                        height: '100%',
                        bgcolor: 'white'
                    })}
                >
                    <Close />
                </IconButton>
            </Grid>
        )}
    </Box>
);
