// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconSettings } from '@tabler/icons';
import { OverrideIcon } from 'types';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconSettings
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export interface DashboardMenuProps {
    id: string;
    title: React.ReactNode | string;
    type: string;
    children: {
        id: string;
        title: React.ReactNode | string;
        type: string;
        url?: string;
        icon?: OverrideIcon;
        breadcrumbs?: boolean;
        children?: {
            id: string;
            title: React.ReactNode | string;
            type: string;
            url?: string;
            icon?: OverrideIcon;
            breadcrumbs?: boolean;
        }[];
    }[];
}

const dashboard: DashboardMenuProps[] = [
    {
        id: 'dashboard',
        title: <FormattedMessage id="dashboard" />,
        type: 'group',
        children: [
            {
                id: 'mainDashboard',
                title: <FormattedMessage id="main" />,
                type: 'item',
                url: 'dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            }
        ]
    }
];

export default dashboard;
