/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@mui/styles';
import { Add, Bolt, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Clear, Event } from '@mui/icons-material';
import {
    Checkbox,
    Chip,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    useTheme,
    styled,
    TextFieldProps
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { useSelector } from 'store';
import { FormattedMessage } from 'react-intl';
import { IRecordField } from 'ui-component/records/types';
import { PanelFieldSection, PanelFieldSectionInline } from 'views/CreateRecord/components';
import { getDateByHour } from 'ui-component/records/utils';
import { IUser } from 'views/backoffice/users/types';
import { isJsonString } from 'utils/stringHelpers';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { DROPDOWN_COLUMNS } from 'ui-component/records/utils/columnHelpers';
import { ObjectListAutocomplete } from 'ui-component/formComponents';
import { DynamicObject } from 'views/TenantProfileSettings/components/types';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { generateInlineObject } from '../PropertiesPanel/utils';

export interface renderInputProps {
    fieldData: IRecordField;
    resetField: any;
    name: string;
    control: any;
    errors: any;
    options?: any[];
    isCustomList?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
    setValue?: any;
    handleOpenViewObjectPanel?: (
        field: IRecordField,
        fieldName: string,
        editable: boolean,
        selectedObject?: Pick<DynamicObject, 'id' | 'objectValues' | 'objectDefinition'> | null
    ) => void;
    inMemoryObjectProperties?: { [key: string]: (ObjectProperty & { value: string })[] };
    hideTitle?: boolean;
    TextFieldComponent?: (props: TextFieldProps) => JSX.Element;
    TextFieldComponentProps?: Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'>;
    ignoreDrawer?: boolean;
    label?: string;
    backgroundAlways?: boolean;
    defaultValue?: string;
}

const useStyles = makeStyles((theme) => ({
    textareaVResize: {
        '& textarea': { resize: 'vertical' }
    }
}));

export const BaseTextField = styled(TextField)(({ theme }) => ({
    '& fieldset': {
        border: 'none !important'
    },

    '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        color: `${theme.palette.secondary.main} !important`,
        '&::placeholder': { color: 'transparent !important' },
        '& svg': {
            color: `${theme.palette.secondary.main} !important`,
            opacity: 0.5
        }
    }
}));

export const RenderInput = ({
    fieldData,
    name,
    control,
    errors,
    options = [],
    isCustomList,
    disabled,
    isRequired,
    resetField,
    setValue,
    handleOpenViewObjectPanel,
    inMemoryObjectProperties,
    hideTitle,
    TextFieldComponent = BaseTextField as (props: TextFieldProps) => JSX.Element,
    TextFieldComponentProps = {},
    ignoreDrawer = false,
    label,
    backgroundAlways = false,
    defaultValue = ''
}: renderInputProps) => {
    const themeSys = useTheme();
    const classes = useStyles();
    const [inputFocused, setInputFocused] = useState(false);
    const [localErrorMessage, setlocalErrorMessage] = useState<string>('');
    const { drawerOpen } = useSelector((state) => state.menu);

    const mapObjectPropertiesToValue = (properties: (ObjectProperty & { value: string })[] | undefined) => {
        const displayValue: string[] = [];
        if (properties !== undefined) {
            properties
                .sort((a, b) => {
                    const orderA = a.order; // ignore upper and lowercase
                    const orderB = b.order; // ignore upper and lowercase
                    if (orderA < orderB) {
                        return -1;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return 0;
                })
                .forEach((val) => {
                    if (val.isDisplayable) {
                        displayValue.push(val.value);
                    }
                });
            return displayValue.join(', ');
        }
        return '';
    };

    const isRequiredName =
        fieldData.required || isRequired ? (
            <>
                <FormattedMessage id={label || name} defaultMessage={label || name} />
                <span style={{ color: 'red' }}>{' *'}</span>
            </>
        ) : (
            <FormattedMessage id={label || name} defaultMessage={label || name} />
        );

    if (fieldData.dataType === 'object')
        return (
            <Grid item xs={12} sx={{ py: 0 }}>
                <Grid container alignItems="center">
                    <Grid item xs={drawerOpen || ignoreDrawer ? 12 : 4}>
                        <Typography
                            style={{
                                paddingTop: 18,
                                paddingLeft: 25,
                                fontWeight: 600,
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: '#616161'
                            }}
                        >
                            {isRequiredName}
                        </Typography>
                    </Grid>
                    <Grid item xs={drawerOpen || ignoreDrawer ? 12 : 8} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                        <Grid style={{ paddingTop: 18, paddingLeft: drawerOpen || ignoreDrawer ? 25 : 10 }}>
                            <Controller
                                name={name}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <>
                                        {mapObjectPropertiesToValue(inMemoryObjectProperties?.[name]) || field.value ? (
                                            <Typography
                                                className="gridObjectValue"
                                                onClick={() => {
                                                    handleOpenViewObjectPanel?.(fieldData, name, !disabled);
                                                }}
                                                sx={(theme) => ({
                                                    color: theme.palette.secondary.main,
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                    textAlign: 'start'
                                                })}
                                            >
                                                {mapObjectPropertiesToValue(inMemoryObjectProperties?.[name]) || field.value}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className="gridObjectValue"
                                                onClick={() => {
                                                    handleOpenViewObjectPanel?.(fieldData, name, !disabled);
                                                }}
                                                sx={(theme) => ({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '6px',
                                                    color: theme.palette.primary[400],
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline'
                                                })}
                                            >
                                                <Add fontSize="small" /> Add Data
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

    if (fieldData.dataType.toLowerCase() === 'dropdown' || DROPDOWN_COLUMNS.includes(name as (typeof DROPDOWN_COLUMNS)[number])) {
        const isListOfObjects = fieldData.listType?.dataType === 'Object';
        const formattedOptions = isListOfObjects
            ? options
                  .map((el) => ({ ...el, label: generateInlineObject(el.dynamicObjectValue.objectValues) }))
                  .sort((a, b) => a.order - b.order)
            : options.sort((a, b) => a.order - b.order);

        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue ?? 0}
                        render={({ field }) =>
                            isListOfObjects ? (
                                <ObjectListAutocomplete
                                    onClickSelectedOption={() => {
                                        const selectedObjectListValue: ListValue = formattedOptions.find((el) => +el.id === +field.value);
                                        handleOpenViewObjectPanel?.(fieldData, name, false, selectedObjectListValue.dynamicObjectValue);
                                    }}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    width="100%"
                                    label={isRequiredName}
                                    variant="outlined"
                                />
                            ) : (
                                <PanelFieldSection
                                    isRecordViewer
                                    noPadding
                                    title={isRequiredName}
                                    hideTitle={hideTitle}
                                    isFocused={inputFocused}
                                >
                                    <TextFieldComponent
                                        variant="outlined"
                                        select
                                        error={!!errors[name]}
                                        helperText={errors[name]?.message}
                                        fullWidth
                                        {...field}
                                        disabled={disabled}
                                        {...TextFieldComponentProps}
                                        {...(field.value &&
                                            field.value !== '0' &&
                                            !disabled && {
                                                InputProps: {
                                                    ...TextFieldComponentProps.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="remove selected option"
                                                                data-test-id="remove-selected-option"
                                                                data-test-input={`test-id-${field.name}`}
                                                                onClick={() => {
                                                                    resetField(field.name, { defaultValue: '0' });
                                                                }}
                                                                edge="end"
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                },
                                                SelectProps: { IconComponent: () => null }
                                            })}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                backgroundColor: inputFocused ? themeSys.palette.secondary.light : 'transparent'
                                            }
                                        }}
                                    >
                                        {options
                                            .filter((el) => !el.enabled && +el.id === +field.value)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem disabled key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    (Disabled){' '}
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                        {options
                                            .filter((el) => el.enabled)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                    </TextFieldComponent>
                                </PanelFieldSection>
                            )
                        }
                    />
                ) : (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={0}
                        render={({ field }) =>
                            isListOfObjects ? (
                                <ObjectListAutocomplete
                                    onClickSelectedOption={() => {
                                        const selectedObject = formattedOptions.find((el) => +el.id === +field.value);
                                        handleOpenViewObjectPanel?.(fieldData, name, false, selectedObject.dynamicObjectValue);
                                    }}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={formattedOptions}
                                    width="100%"
                                    label={isRequiredName}
                                    variant="outlined"
                                />
                            ) : (
                                <PanelFieldSectionInline noPadding hideTitle={hideTitle} title={isRequiredName} isFocused={inputFocused}>
                                    <TextFieldComponent
                                        id={`test-selector-${field.name}`}
                                        variant="outlined"
                                        select
                                        error={!!errors[name]}
                                        helperText={errors[name]?.message}
                                        fullWidth
                                        {...field}
                                        disabled={disabled}
                                        {...TextFieldComponentProps}
                                        {...(field.value &&
                                            field.value !== '0' &&
                                            !disabled && {
                                                InputProps: {
                                                    ...TextFieldComponentProps.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="remove selected option"
                                                                data-test-id="remove-selected-option"
                                                                data-test-input={`test-id-${field.name}`}
                                                                onClick={() => {
                                                                    resetField(field.name, { defaultValue: '0' });
                                                                }}
                                                                edge="end"
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                },
                                                SelectProps: { IconComponent: () => null }
                                            })}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                backgroundColor: inputFocused ? themeSys.palette.secondary.light : 'transparent'
                                            }
                                        }}
                                    >
                                        {options
                                            .filter((el) => !el.enabled && +el.id === +field.value)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem disabled key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    (Disabled){' '}
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                        {options
                                            .filter((el) => el.enabled)
                                            .map((option: { id: string; name?: string; userValue?: IUser; value?: string }) => (
                                                <MenuItem key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                                    <em>{isCustomList ? option.userValue?.name || option.value : option.name}</em>
                                                </MenuItem>
                                            ))}
                                    </TextFieldComponent>
                                </PanelFieldSectionInline>
                            )
                        }
                    />
                )}
            </>
        );
    }

    if (fieldData.dataType === 'string' || fieldData.dataType === 'url' || fieldData.dataType === 'textarea')
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextFieldComponent
                                    multiline={fieldData.dataType === 'textarea'}
                                    className={fieldData.dataType === 'textarea' ? classes.textareaVResize : undefined}
                                    variant="outlined"
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        '& input, textarea': {
                                            background: `${themeSys.palette.primary[300]} !important`
                                        },
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                            backgroundColor: `${themeSys.palette.secondary.light} !important`,
                                            color: `${themeSys.palette.secondary.main} !important`,
                                            '&::placeholder': { color: 'transparent !important' },
                                            '& svg': {
                                                color: `${themeSys.palette.secondary.main} !important`,
                                                opacity: 0.5
                                            }
                                        }
                                    }}
                                    multiline={fieldData.dataType === 'textarea'}
                                    className={fieldData.dataType === 'textarea' ? classes.textareaVResize : undefined}
                                    variant="outlined"
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message}
                                    fullWidth
                                    {...field}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType === 'currency' || fieldData.dataType === 'number')
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <NumberFormat
                                    customInput={TextFieldComponent}
                                    fixedDecimalScale={fieldData.dataType === 'currency'}
                                    thousandSeparator={fieldData.dataType === 'currency' ? ',' : ''}
                                    decimalScale={fieldData.dataType === 'currency' ? 2 : 0}
                                    variant="outlined"
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message}
                                    fullWidth
                                    {...field}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <NumberFormat
                                    customInput={TextFieldComponent}
                                    fixedDecimalScale={fieldData.dataType === 'currency'}
                                    thousandSeparator={fieldData.dataType === 'currency' ? ',' : ''}
                                    decimalScale={fieldData.dataType === 'currency' ? 2 : 0}
                                    variant="outlined"
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message}
                                    fullWidth
                                    {...field}
                                    disabled={disabled}
                                    {...TextFieldComponentProps}
                                    onFocus={() => {
                                        setInputFocused(true);
                                    }}
                                    onBlur={() => {
                                        setInputFocused(false);
                                    }}
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType.toLowerCase() === 'multiselect') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <>
                                    <Autocomplete
                                        multiple
                                        disabled={disabled}
                                        id="tags-outlined"
                                        sx={{
                                            '& input, .MuiSelect-select, .MuiInputBase-root': {
                                                background: `${themeSys.palette.primary[300]} !important`
                                            },
                                            '& fieldset': {
                                                border: 'none !important'
                                            },
                                            '& :focus-visible, :focus, :active, .Mui-focused': {
                                                '& .MuiSelect-select, .MuiInputBase-root': {
                                                    background: `${themeSys.palette.secondary.light} !important`
                                                },
                                                backgroundColor: `${themeSys.palette.secondary.light} !important`,
                                                color: `${themeSys.palette.secondary.main} !important`
                                            }
                                        }}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        renderTags={(value, getTagProps, ownerState) =>
                                            value.map((option: any, index: number) => {
                                                const backgroundColors = [themeSys.palette.secondary.main, '#1845A7', '#005208'];

                                                return (
                                                    <Chip
                                                        sx={(theme) => ({
                                                            backgroundColor: `${backgroundColors[index % 3]} !important`,
                                                            color: `#fff !important`,
                                                            borderRadius: '20px !important',
                                                            width: '100px'
                                                        })}
                                                        label={isCustomList ? option.userValue?.name || option.value : option.name}
                                                        deleteIcon={<Clear />}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        value={options.filter((option: any) =>
                                            field.value !== '0' && isJsonString(field.value) && Array.isArray(JSON.parse(field.value))
                                                ? JSON.parse(field.value || '[]').includes(+option.id)
                                                : false
                                        )}
                                        onChange={(_e, value) => {
                                            setValue(name, value.length === 0 ? '0' : JSON.stringify(value.map((val: any) => +val.id)));
                                        }}
                                        options={options}
                                        getOptionLabel={(option: any) =>
                                            isCustomList ? option?.userValue?.name || option?.value : option?.name
                                        }
                                        filterSelectedOptions
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {errors[name] && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errors[name]?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <>
                                    <Autocomplete
                                        multiple
                                        disabled={disabled}
                                        id="tags-outlined"
                                        sx={{
                                            '& input, .MuiSelect-select, .MuiInputBase-root': {
                                                background: `${themeSys.palette.primary[300]} !important`
                                            },
                                            '& fieldset': {
                                                border: 'none !important'
                                            },
                                            '& :focus-visible, :focus, :active, .Mui-focused': {
                                                '& .MuiSelect-select, .MuiInputBase-root': {
                                                    background: `${themeSys.palette.secondary.light} !important`
                                                },
                                                backgroundColor: `${themeSys.palette.secondary.light} !important`,
                                                color: `${themeSys.palette.secondary.main} !important`
                                            }
                                        }}
                                        onFocus={() => {
                                            setInputFocused(true);
                                        }}
                                        onBlur={() => {
                                            setInputFocused(false);
                                        }}
                                        renderTags={(value, getTagProps, ownerState) =>
                                            value.map((option: any, index: number) => {
                                                const backgroundColors = [themeSys.palette.secondary.main, '#1845A7', '#005208'];

                                                return (
                                                    <Chip
                                                        sx={(theme) => ({
                                                            backgroundColor: `${backgroundColors[index % 3]} !important`,
                                                            color: `#fff !important`,
                                                            borderRadius: '20px !important',
                                                            width: '100px'
                                                        })}
                                                        label={isCustomList ? option.userValue?.name || option.value : option.name}
                                                        deleteIcon={<Clear />}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        value={options.filter((option: any) =>
                                            field.value !== '0' && isJsonString(field.value) && Array.isArray(JSON.parse(field.value))
                                                ? JSON.parse(field.value || '[]').includes(+option.id)
                                                : false
                                        )}
                                        onChange={(_e, value) => {
                                            setValue(name, value.length === 0 ? '0' : JSON.stringify(value.map((val: any) => +val.id)));
                                        }}
                                        options={options}
                                        getOptionLabel={(option: any) =>
                                            isCustomList ? option?.userValue?.name || option?.value : option?.name
                                        }
                                        filterSelectedOptions
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {errors[name] && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errors[name]?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'date')
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setlocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setlocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        inputRef={ref}
                                        value={datePicker}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps
                                                }}
                                                error={!!errors[name] || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                variant={TextFieldComponentProps.variant || params.variant}
                                                size={TextFieldComponentProps.size || params.size}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setlocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setlocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        inputRef={ref}
                                        value={datePicker}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps
                                                }}
                                                error={!!errors[name] || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                variant={TextFieldComponentProps.variant || params.variant}
                                                size={TextFieldComponentProps.size || params.size}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );

    if (fieldData.dataType === 'datetime') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ todayButtonLabel: 'NOW' }}>
                                    <DateTimePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setlocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setlocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        value={datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        components={{
                                            OpenPickerIcon: () => (
                                                <Tooltip title="Pick a date">
                                                    <Event />
                                                </Tooltip>
                                            )
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today']
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                {...params}
                                                {...TextFieldComponentProps}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            <Tooltip title="Now">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setlocalErrorMessage('');
                                                                        onChange(new Date());
                                                                    }}
                                                                    sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                                >
                                                                    <Bolt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {params.InputProps?.endAdornment}
                                                        </>
                                                    )
                                                }}
                                                error={!!errors[name] || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ todayButtonLabel: 'NOW' }}>
                                    <DateTimePicker
                                        onChange={(newValue) => {
                                            if (newValue === null) {
                                                onChange('');
                                                setlocalErrorMessage('');
                                            } else {
                                                const isValidDatetime = Date.parse(newValue);
                                                setlocalErrorMessage(isValidDatetime ? '' : 'Invalid date format');
                                                onChange(newValue);
                                            }
                                        }}
                                        value={datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        components={{
                                            OpenPickerIcon: () => (
                                                <Tooltip title="Pick a date">
                                                    <Event />
                                                </Tooltip>
                                            )
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today']
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                {...params}
                                                {...TextFieldComponentProps}
                                                InputProps={{
                                                    ...TextFieldComponentProps.InputProps,
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            <Tooltip title="Now">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setlocalErrorMessage('');
                                                                        onChange(new Date());
                                                                    }}
                                                                    sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                                >
                                                                    <Bolt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {params.InputProps?.endAdornment}
                                                        </>
                                                    )
                                                }}
                                                error={!!errors[name] || !!localErrorMessage}
                                                helperText={errors.dueDate?.message || localErrorMessage}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'time') {
        return (
            <>
                {drawerOpen || ignoreDrawer ? (
                    <PanelFieldSection isRecordViewer noPadding title={isRequiredName} isFocused={inputFocused} hideTitle={hideTitle}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={(newValue) => {
                                            const isValidDatetime = Date.parse(newValue);
                                            if (isValidDatetime) {
                                                onChange(newValue);
                                            } else {
                                                onChange('');
                                            }
                                        }}
                                        value={!Date.parse(datePicker) ? getDateByHour(datePicker) : datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                error={!!errors[name]}
                                                helperText={errors.dueDate?.message}
                                                {...TextFieldComponentProps}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSection>
                ) : (
                    <PanelFieldSectionInline noPadding title={isRequiredName} isFocused={inputFocused}>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value: datePicker, ref } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={(newValue) => {
                                            const isValidDatetime = Date.parse(newValue);
                                            if (isValidDatetime) {
                                                onChange(newValue);
                                            } else {
                                                onChange('');
                                            }
                                        }}
                                        value={!Date.parse(datePicker) ? getDateByHour(datePicker) : datePicker}
                                        inputRef={ref}
                                        disabled={disabled}
                                        renderInput={(params) => (
                                            <TextFieldComponent
                                                onFocus={() => {
                                                    setInputFocused(true);
                                                }}
                                                onBlur={() => {
                                                    setInputFocused(false);
                                                }}
                                                fullWidth
                                                {...params}
                                                error={!!errors[name]}
                                                helperText={errors.dueDate?.message}
                                                {...TextFieldComponentProps}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </PanelFieldSectionInline>
                )}
            </>
        );
    }

    if (fieldData.dataType === 'checkbox') {
        return (
            <Grid
                item
                container
                justifyContent={backgroundAlways ? 'center' : 'flex-start'}
                alignItems={backgroundAlways ? 'center' : 'flex-start'}
                xs={12}
                sx={{
                    padding: 0,
                    pt: backgroundAlways ? '0' : '10px',
                    pl: backgroundAlways ? '0' : '25px',
                    borderRadius: '8px',
                    height: '38px',
                    bgcolor: (theme) => (backgroundAlways ? theme.palette.primary[300] : 'inherit')
                }}
            >
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) =>
                        hideTitle ? (
                            <Checkbox
                                defaultChecked
                                checked={!!field.value && field.value === 'true'}
                                disabled={disabled}
                                onChange={(event) => {
                                    setValue(name, String(event.target.checked), { shouldValidate: true });
                                }}
                                sx={(theme) => ({
                                    padding: 0,
                                    color: theme.palette.primary[400],
                                    '&.Mui-checked': { color: theme.palette.primary.dark },
                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                })}
                                icon={<CheckBoxOutlineBlankOutlined />}
                                checkedIcon={<CheckBoxOutlined />}
                            />
                        ) : (
                            <>
                                <FormGroup sx={{ width: '100%' }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={drawerOpen && ignoreDrawer ? 12 : 6}>
                                            <Typography style={{ fontWeight: 600, fontSize: '13px' }}>{name}</Typography>
                                        </Grid>
                                        <Grid xs={drawerOpen && ignoreDrawer ? 12 : 6} sx={{ display: 'flex' }}>
                                            <Grid xs={4} sx={{ textAlign: 'left' }}>
                                                <Checkbox
                                                    defaultChecked
                                                    checked={!!field.value && field.value === 'true'}
                                                    disabled={disabled}
                                                    onChange={(event) => {
                                                        setValue(name, String(event.target.checked), { shouldValidate: true });
                                                    }}
                                                    sx={(theme) => ({
                                                        padding: 0,
                                                        color: theme.palette.primary[400],
                                                        '&.Mui-checked': { color: theme.palette.primary.dark },
                                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                                    })}
                                                    icon={<CheckBoxOutlineBlankOutlined />}
                                                    checkedIcon={<CheckBoxOutlined />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                {errors[name] && (
                                    <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                        {errors[name]?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )
                    }
                />
            </Grid>
        );
    }

    if (fieldData.dataType === 'multiselect-checkbox') {
        return (
            <Grid item xs={12} sx={{ py: '10px', paddingLeft: '25px' }}>
                {!hideTitle && <Typography style={{ paddingTop: 18, fontWeight: 600, fontSize: '13px' }}>{isRequiredName}</Typography>}
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <FormGroup>
                            {options.map((option) => (
                                <>
                                    <FormGroup style={{ padding: '5px 10px' }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                                    {option.userValue?.name || option.value}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={6} sx={{ display: 'flex' }}>
                                                <Grid xs={4} sx={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        defaultChecked
                                                        checked={
                                                            !!field.value &&
                                                            isJsonString(field.value) &&
                                                            Array.isArray(JSON.parse(field.value)) &&
                                                            JSON.parse(field.value).some(
                                                                (x: string | number) => String(x) === String(option.id)
                                                            )
                                                        }
                                                        disabled={disabled}
                                                        onChange={(event) => {
                                                            let currentValue = isJsonString(field.value)
                                                                ? JSON.parse(field.value || '[]')
                                                                : [];
                                                            if (!Array.isArray(currentValue)) {
                                                                currentValue = [];
                                                            }
                                                            if (event.target.checked) {
                                                                currentValue.push(+option.id);
                                                            } else {
                                                                currentValue.splice(currentValue.indexOf(+option.id), 1);
                                                            }
                                                            setValue(name, currentValue.length ? JSON.stringify(currentValue) : '0');
                                                        }}
                                                        sx={(theme) => ({
                                                            padding: 0,
                                                            color: theme.palette.primary[400],
                                                            '&.Mui-checked': { color: theme.palette.primary.dark },
                                                            '& .MuiSvgIcon-root': { fontSize: 30 }
                                                        })}
                                                        icon={<CheckBoxOutlineBlankOutlined />}
                                                        checkedIcon={<CheckBoxOutlined />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                    {errors[name] && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errors[name]?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            ))}
                        </FormGroup>
                    )}
                />
            </Grid>
        );
    }

    return <></>;
};

export default RenderInput;
