import { HighlightOff, HighlightOffOutlined, History } from '@mui/icons-material';
import { Grid, IconButton, List, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LineItemIcon } from 'views/DocumentViewer/components/Icons';
import { IconMessageCircle2, IconPaperclip } from '@tabler/icons';
import { LogReportMode, PanelTitle } from '../utils';
import { LogReportSelector } from '../LogReportPanel/components';

export interface HeaderPanelProps {
    title: string;
    showHistory: boolean;
    toggleEditForm: () => void;
    toggleLogReport: () => void;
    changePanel: PanelTitle;
    setLogReportMode: (mode: LogReportMode) => void;
    isOpenForm: boolean;
    onShowFullHistory: () => void;
    isCreate?: boolean;
    onToggleLineItems?: () => void;
    isObjectPanel?: boolean;
    onGoBack?: () => void;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
}

export const HeaderPanel = ({
    title,
    showHistory,
    onShowFullHistory,
    toggleEditForm,
    changePanel,
    setLogReportMode,
    toggleLogReport,
    isOpenForm,
    isCreate,
    onToggleLineItems,
    onGoBack,
    isObjectPanel = false,
    onToggleComments,
    onToggleAttachments
}: HeaderPanelProps) => {
    const boxStyle = {
        // TODO: change this to use the theme
        backgroundColor: 'transparent',
        display: 'flex',
        padding: '19px 0px 0px 0px'
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    };

    return (
        <Box position="static" style={boxStyle} data-testid="header-panel">
            <List>
                <Typography
                    variant={isObjectPanel ? 'h4' : 'h3'}
                    component="div"
                    sx={(theme) => ({
                        paddingLeft: '10px',
                        color: theme.palette.secondary.main,
                        fontWeight: '500',
                        fontSize: '16px',
                        letterSpacing: '0.15px',
                        lineHeight: '24px'
                    })}
                >
                    {isObjectPanel && (
                        <Box
                            onClick={() => onGoBack?.()}
                            component="span"
                            sx={(theme) => ({ color: theme.palette.primary[400], cursor: 'pointer' })}
                        >
                            Details {`> `}
                        </Box>
                    )}
                    {title}
                </Typography>
            </List>
            {isCreate && (
                <Box sx={{ flexGrow: 1 }}>
                    <ToggleButtonGroup
                        sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end', '& button': { height: '100%' } }}
                    >
                        {isObjectPanel && (
                            <Tooltip title="Close Object Panel">
                                <IconButton
                                    onClick={() => onGoBack?.()}
                                    value="Close Object Panel"
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>
                        )}
                    </ToggleButtonGroup>
                </Box>
            )}

            <Box sx={{ flexGrow: 1 }}>
                <ToggleButtonGroup sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end', '& button': { height: '100%' } }}>
                    {onToggleComments && !isCreate && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Comments">
                                <IconButton
                                    onClick={onToggleComments}
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <IconMessageCircle2 />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {onToggleAttachments && !isCreate && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Attachments">
                                <IconButton
                                    onClick={onToggleAttachments}
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <IconPaperclip />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {!!onToggleLineItems && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Line Items">
                                <IconButton
                                    onClick={onToggleLineItems}
                                    value="line items"
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <LineItemIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {changePanel === PanelTitle.LogReport && <LogReportSelector onChangeSelection={setLogReportMode} />}
                    {!isCreate && (
                        <Grid>
                            <Tooltip title="Log report">
                                <IconButton
                                    onClick={toggleLogReport}
                                    value="Log report"
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    {changePanel === PanelTitle.LogReport ? (
                                        <HighlightOffOutlined fontSize="medium" />
                                    ) : (
                                        <History fontSize="medium" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}

                    {isObjectPanel && (
                        <Tooltip title="Close Object Panel">
                            <IconButton
                                onClick={() => onGoBack?.()}
                                value="Close Object Panel"
                                size="medium"
                                sx={(theme) => ({
                                    color: theme.palette.primary[400],
                                    backgroundColor: '#FFF',
                                    fontWeight: 600,
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                })}
                            >
                                <HighlightOff />
                            </IconButton>
                        </Tooltip>
                    )}
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
};

export default HeaderPanel;
