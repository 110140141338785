import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Paper, TextField } from '@mui/material';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { EditorSection } from './Editor';

export type CommentFieldProps = {
    isFocused: boolean;
    onBlur: () => void;
    onFocus: () => void;
    onSendComment: (message: string) => void;
    initialContent?: string;
};

export const CommentField = ({ isFocused, onBlur, onFocus, onSendComment, initialContent }: CommentFieldProps) => {
    const intl = useIntl();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const handleSaveComment = () => {
        if (editorState.getCurrentContent().hasText()) {
            const rawDraftContentState = convertToRaw(editorState.getCurrentContent());
            const message = JSON.stringify(rawDraftContentState);
            onSendComment(message);
        }
        onBlur();
        setEditorState(() => EditorState.createEmpty());
    };

    const handleCancelComment = () => {
        setEditorState(EditorState.createEmpty());
        onBlur();
    };

    useEffect(() => {
        if (!initialContent) return;
        const content = JSON.parse(initialContent);
        const contentState = convertFromRaw(content);
        setEditorState(EditorState.createWithContent(contentState));
    }, [initialContent]);

    useEffect(() => {
        if (isFocused) setEditorState((s) => EditorState.moveFocusToEnd(s));
    }, [isFocused]);

    if (!isFocused)
        return (
            <TextField
                variant="filled"
                onClick={onFocus}
                size="small"
                InputProps={{ disableUnderline: true, sx: { borderRadius: '10px', paddingLeft: 2 } }}
                placeholder={intl.formatMessage({ id: 'commentPanel.commentField.initial.placeholder' })}
                hiddenLabel
                fullWidth
            />
        );

    return (
        <>
            <Paper sx={{ bgcolor: '#F5F6F7', minHeight: '100px', borderRadius: '8px' }}>
                <EditorSection editorState={editorState} setEditorState={setEditorState} />
            </Paper>
            <Grid container justifyContent="flex-end" sx={{ mt: '10px' }}>
                <Grid item xs="auto">
                    <Button
                        variant="outlined"
                        onClick={handleCancelComment}
                        sx={{ width: '80px', height: '40px', borderRadius: '8px' }}
                        color="secondary"
                        fullWidth
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={handleSaveComment}
                        variant="contained"
                        sx={{ width: '80px', height: '40px', borderRadius: '8px', ml: '12px' }}
                        color="secondary"
                        fullWidth
                    >
                        <FormattedMessage id="save" />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
