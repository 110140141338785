import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';

// third-party
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
// import dayjs from 'dayjs';
import * as CognitoSDK from '@aws-sdk/client-cognito-identity-provider';
import { useMutation } from '@apollo/client';

import { Auth } from 'aws-amplify';
// reducer - state management
import { LOGIN, LOGIN_COGNITO, LOGOUT, SAVE_COGNITO_USER, SET_ASSOCIATE_SOFTWARE_TOKEN } from 'store/actions';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import accountReducer from 'store/accountReducer';

// project imports
import { AWS_CONFIGURATION } from 'config';
import { MUTATION_LOGIN } from 'graphql/mutations/auth';
import { useMyTenants } from 'hooks/useMyTenants';
import { isTokenExpired } from 'utils';
import { AUTH_ROUTES, awsAdminClient, cleanLocalStorage, initialState, setSession } from 'utils/loginHelpers';
import { IUser } from 'views/backoffice/users/types';
import Loader from 'ui-component/Loader';
import { AWSCognitoContextType } from 'types/auth';
import { useIntl } from 'react-intl';

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const storeDispatch = useDispatch();
    const { getUserDetails, loading: loadingTenants } = useMyTenants();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [recoverEmail, setRecoverEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [shouldSkipSetupMFA, setShouldSkipSetupMFA] = useState(false);
    const [shouldShowSkipMFA, setShouldShowSkipMFA] = useState(true);
    const [loggedUser, setLoggedUser] = useState(null as CognitoUser | null);
    const [mfaType, setMfaType] = useState<'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null>(null);
    const [loggedUserAuthData, setLoggedUserAuthData] = useState({ email: '', password: '' } as { email: string; password: string });

    const [Login, { loading: loadingLogin }] = useMutation(MUTATION_LOGIN, {
        onCompleted: (data) => {
            localStorage.setItem('backend_jwt', data.SaasUserLogin);
            localStorage.setItem('tenantSelected', 'true');
            loginWithBackendData();
        }
    });

    // Backend login helpers

    const loginWithBackendData = () => {
        dispatch({
            type: LOGIN
        });
    };

    const handleLoginInAnotherTenant = useCallback(async () => {
        const { pathname } = window.location;
        const currentTenantPath = localStorage.getItem('tenantPath') ?? '';
        const newTenantPath = pathname.slice(1).split('/')[0];
        const tenantList = (await getUserDetails()).data?.getUserDetails ?? [];
        const tenantFromNewPath = tenantList.find(({ tenant }) => tenant.url.toLowerCase() === newTenantPath.toLowerCase());

        if (tenantFromNewPath) {
            localStorage.setItem('tenant_id', tenantFromNewPath.tenant.id);
            localStorage.setItem('tenantPath', `/${tenantFromNewPath.tenant.url}`);

            await Login();

            storeDispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    message: `${intl.formatMessage({ id: 'youAreNowLoggedIn' })} ${tenantFromNewPath.tenant.name}`,
                    variant: 'alert',
                    alert: { color: 'info' },
                    close: false
                })
            );
        } else {
            navigate(`${currentTenantPath}/dashboard`);
            storeDispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    message: intl.formatMessage({ id: 'notAccessTenant' }),
                    variant: 'alert',
                    alert: { color: 'info' },
                    close: false
                })
            );
        }
    }, [Login, getUserDetails, storeDispatch, navigate, intl]);

    // Cognito user list helpers

    const getUsersData = async (users: IUser[]) => {
        const commands = users.map(
            (user) =>
                new CognitoSDK.AdminGetUserCommand({
                    UserPoolId: AWS_CONFIGURATION.Auth.userPoolId,
                    Username: user.email
                })
        );

        const promiseResult = await Promise.allSettled(commands.map((c) => awsAdminClient.send(c)));

        const cognitoUserData = promiseResult
            .filter((r) => r.status === 'fulfilled')
            .map((user) => {
                if (user.status === 'fulfilled') {
                    return user.value;
                }
                return null;
            });
        cognitoUserData.forEach((usr) => {
            usr?.UserAttributes?.forEach((attr) => {
                if (attr.Name === 'email' || attr.Name === 'sub') console.log(attr.Name, attr.Value);
            });
        });
        const usersMapped = users.map((user) => {
            const userFromCognito = cognitoUserData.find((cognitoData) =>
                cognitoData?.UserAttributes?.some(
                    (attr: { Name?: string; Value?: string }) => attr.Name === 'email' && attr.Value === user.email
                )
            );
            if (userFromCognito) {
                return { ...user, hasMFA: Object.keys(userFromCognito).includes('PreferredMfaSetting') };
            }
            return { ...user, hasMFA: false };
        });
        return usersMapped;
    };

    const getCurrentUser = useCallback(async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setLoggedUser(user);
            return user;
        } catch (error: any) {
            console.log('error getting user', error);
            return { error };
        }
    }, []);

    // Cognito auth methods

    /**
     * Promise used to login a user
     *
     * @param email {string}
     * @param password {string}
     * @returns
     */
    const login = async (
        email: string,
        password: string
    ): Promise<{ success?: boolean; error?: any; totpRequired?: boolean; totpSetUp?: true }> => {
        try {
            setLoggedUserAuthData({ email, password });
            const user = await Auth.signIn({
                username: email,
                password
            });

            setLoggedUser(user);

            if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setMfaType(user.challengeName);
                return { totpRequired: true };
            }

            setupAfterSignIn(user, email);

            if (user.preferredMFA === 'NOMFA') {
                const secretCode = await Auth.setupTOTP(user);
                localStorage.setItem('associateSoftwareToken', secretCode);
                dispatch({
                    type: SET_ASSOCIATE_SOFTWARE_TOKEN,
                    payload: {
                        isLoggedIn: false,
                        isCognitoLoggedIn: true,
                        associateSoftwareToken: secretCode
                    }
                });

                // navigate(`/setupmfa`, { replace: true });
                return { totpSetUp: true };
            }

            setShouldSkipSetupMFA(true);
            localStorage.setItem('shouldSkipSetupMFA', 'true');

            return { success: true };
        } catch (error: any) {
            console.log('login error', error);
            return { error };
        }
    };

    const setupAfterSignIn = (user: CognitoUser | any, email?: string) => {
        setSession(user.signInUserSession.accessToken.jwtToken, user.signInUserSession.idToken.jwtToken);
        setRecoverEmail('');
        setNewPassword('');

        if (email) localStorage.setItem('userEmail', email);

        dispatch({
            type: SAVE_COGNITO_USER,
            payload: {
                isLoggedIn: false,
                user: {
                    id: user.signInUserSession.getIdToken().payload.sub,
                    email: email || user.getUsername()
                }
            }
        });
    };

    const rememberDevice = async () => {
        try {
            await Auth.rememberDevice();
        } catch (error) {
            console.log('Error remembering device', error);
        }
    };

    const logout = useCallback(
        async (force = false) => {
            try {
                const currentUser = await getCurrentUser();
                if (currentUser || currentUser?.preferredMFA !== 'NOMFA') {
                    await Auth.signOut();
                    cleanLocalStorage();
                    dispatch({ type: LOGOUT });
                    setLoggedUser(null);
                }

                setShouldSkipSetupMFA(false);
                setSession(null);
            } catch (error) {
                console.log('error signing out: ', error);
            }
            if (force || AUTH_ROUTES.every((route) => !window.location.pathname.includes(route))) navigate(`/login`);
        },
        [getCurrentUser, navigate]
    );

    const recover = async (email: string = recoverEmail) => {
        await Auth.forgotPassword(email);
        setRecoverEmail(email);
    };

    const resetPassword = async (verificationCode: any, password: string) => {
        await Auth.forgotPasswordSubmit(recoverEmail, verificationCode, password);
        setNewPassword(password);
    };

    // SignUp methods

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        const res = await Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                name: firstName,
                family_name: lastName
            }
        });
        return res;
    };

    const resendConfirmationCode = async (email: string) => {
        const res = await Auth.resendSignUp(email);
        return res;
    };

    const confirmUser = async (email: string, code: string) => {
        const res = await Auth.confirmSignUp(email, code, { forceAliasCreation: true });

        return res;
    };

    // Cognito MFA helpers

    const DisableMFA = async (cognitoUserId: string | undefined) => {
        if (!cognitoUserId) return;
        const getDevicesCommand = new CognitoSDK.AdminListDevicesCommand({
            UserPoolId: AWS_CONFIGURATION.Auth.userPoolId,
            Username: cognitoUserId,
            Limit: 60
        });
        const { Devices: devices } = await awsAdminClient.send(getDevicesCommand);

        if (devices) {
            for await (const device of devices) {
                await awsAdminClient.send(
                    new CognitoSDK.AdminForgetDeviceCommand({
                        UserPoolId: AWS_CONFIGURATION.Auth.userPoolId,
                        Username: cognitoUserId,
                        DeviceKey: device.DeviceKey
                    })
                );
            }
        }

        const disableMFACommand = new CognitoSDK.AdminSetUserMFAPreferenceCommand({
            UserPoolId: AWS_CONFIGURATION.Auth.userPoolId,
            Username: cognitoUserId,
            SoftwareTokenMfaSettings: {
                Enabled: false,
                PreferredMfa: false
            }
        });
        await awsAdminClient.send(disableMFACommand);
    };

    const sendTOTP = async (code: string, rememberMe?: boolean): Promise<{ error?: any; success?: true }> => {
        try {
            const user = await Auth.confirmSignIn(loggedUser, code, mfaType);

            setShouldSkipSetupMFA(true);
            localStorage.setItem('shouldSkipSetupMFA', 'true');

            setupAfterSignIn(user);

            if (rememberMe) {
                await rememberDevice();
            }

            return { success: true };
        } catch (error: any) {
            console.log('error sending mfa code', error);
            return { error };
        }
    };

    const setPreferredMFA = async (): Promise<{ result?: any; error?: any }> => {
        try {
            const result = await Auth.setPreferredMFA(loggedUser, 'SOFTWARE_TOKEN_MFA');
            return { result };
        } catch (error: any) {
            return { error };
        }
    };

    const verifyMFASetupToken = async (code: string) => {
        const cognitoUserSession = await Auth.verifyTotpToken(loggedUser, code);
        return cognitoUserSession;
    };

    const timesMFASkipped = async () => {
        if (loggedUser) {
            const timesSkipped = Number((loggedUser as any).attributes['custom:mfa_skipped'] || 0);

            if (timesSkipped > 1000 && !loggedUserAuthData.email.includes('85623')) {
                setShouldShowSkipMFA(false);
            }
            return timesSkipped;
        }
        return 0;
    };

    const incrementSkipTimes = async () => {
        const value = (await timesMFASkipped()) + 1;

        await Auth.updateUserAttributes(loggedUser, {
            'custom:mfa_skipped': String(value)
        });
    };

    useEffect(() => {
        if (loadingTenants) return;
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const backendToken = window.localStorage.getItem('backend_jwt');
                const tenantPath = window.localStorage.getItem('tenantPath');
                const associateSoftwareToken = window.localStorage.getItem('associateSoftwareToken');
                const { pathname } = window.location;

                if (associateSoftwareToken) {
                    dispatch({
                        type: SET_ASSOCIATE_SOFTWARE_TOKEN,
                        payload: { isLoggedIn: true, associateSoftwareToken }
                    });
                }

                if (!serviceToken || isTokenExpired(serviceToken)) {
                    throw new Error('No service token or expired');
                }

                if (serviceToken && isTokenExpired(backendToken)) {
                    getCurrentUser();
                    dispatch({ type: LOGIN_COGNITO });
                }

                if (serviceToken && !isTokenExpired(backendToken)) {
                    loginWithBackendData();
                }

                if (AUTH_ROUTES.every((route) => route !== pathname) && !pathname.includes(String(tenantPath))) {
                    await handleLoginInAnotherTenant();
                }
            } catch (err) {
                // Sets redirect path to pass it when the login is performed
                const { pathname, search } = window.location;
                if (AUTH_ROUTES.every((route) => route !== pathname)) {
                    window.localStorage.setItem('redirectPath', `${pathname}${search}`);
                }
                console.error(err);
                logout();
            }
        };

        init();
    }, [loadingTenants, logout, handleLoginInAnotherTenant, getCurrentUser]);

    // Check if the user is logged in and has a valid token when location changes
    useEffect(() => {
        try {
            const backendToken = window.localStorage.getItem('backend_jwt');
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (isTokenExpired(backendToken) && isTokenExpired(serviceToken)) throw new Error('No service token or expired');
        } catch (error) {
            logout();
        }
    }, [location, logout]);

    if ((state.isInitialized !== undefined && !state.isInitialized) || loadingLogin || loadingTenants) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                getUsersData,
                login,
                logout,
                register,
                loginWithBackendData,
                recover,
                recoverEmail,
                newPassword,
                resetPassword,
                resendConfirmationCode,
                confirmUser,
                shouldSkipSetupMFA,
                setShouldSkipSetupMFA,
                loggedUser,
                loggedUserAuthData,
                incrementSkipTimes,
                shouldShowSkipMFA,
                setShouldShowSkipMFA,
                timesMFASkipped,
                DisableMFA,
                sendTOTP,
                setPreferredMFA,
                verifyMFASetupToken
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
