import { ExpandMore, Menu } from '@mui/icons-material';
import { Button, ClickAwayListener, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from '@mui/material';
import { MouseEvent, useState } from 'react';
import Transitions from 'ui-component/extended/Transitions';
import { LogReportMode } from '../../utils';

export type LogReportSelectorProps = {
    onChangeSelection: (newSelection: LogReportMode) => void;
};

export const LogReportSelector = ({ onChangeSelection }: LogReportSelectorProps) => {
    const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);

    const isOpenSelector = !!anchorRef;

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
        setAnchorRef(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorRef(null);
    };

    const handleChangeSelection = (newSelection: LogReportMode) => () => {
        onChangeSelection(newSelection);
        handleClose();
    };

    return (
        <>
            <Button
                size="medium"
                color="primary"
                onClick={handleOpen}
                sx={(theme) => ({
                    color: theme.palette.primary[400],
                    backgroundColor: '#FFF',
                    fontWeight: 600,
                    borderRadius: '30px',
                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                })}
            >
                <Menu fontSize="medium" />
                <ExpandMore fontSize="medium" />
            </Button>
            <Popper
                onResize={null}
                onResizeCapture={null}
                placement="bottom-end"
                open={isOpenSelector}
                anchorEl={anchorRef}
                role={undefined}
                transition
                disablePortal
                sx={{ border: `1px solid #D9D9D9`, borderRadius: '8px !important', zIndex: 9 }}
                popperOptions={{
                    modifiers: [{ name: 'offset', options: { offset: [0, 14] } }]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={isOpenSelector} {...TransitionProps}>
                            <Paper sx={{ borderRadius: '8px !important' }}>
                                {isOpenSelector && (
                                    <List>
                                        <ListItem divider disablePadding>
                                            <ListItemButton
                                                sx={(theme) => ({
                                                    '&:hover': {
                                                        color: theme.palette.primary[400],
                                                        background: theme.palette.primary[300]
                                                    }
                                                })}
                                                onClick={handleChangeSelection(LogReportMode.All)}
                                            >
                                                <ListItemText primary="Record History" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem divider disablePadding>
                                            <ListItemButton
                                                sx={(theme) => ({
                                                    '&:hover': { color: theme.palette.primary[400], background: theme.palette.primary[300] }
                                                })}
                                                onClick={handleChangeSelection(LogReportMode.Objects)}
                                            >
                                                <ListItemText primary="Objects History" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem divider disablePadding>
                                            <ListItemButton
                                                sx={(theme) => ({
                                                    '&:hover': { color: theme.palette.primary[400], background: theme.palette.primary[300] }
                                                })}
                                                onClick={handleChangeSelection(LogReportMode.LineItems)}
                                            >
                                                <ListItemText primary="Line items History" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={(theme) => ({
                                                    '&:hover': { color: theme.palette.primary[400], background: theme.palette.primary[300] }
                                                })}
                                                onClick={handleChangeSelection(LogReportMode.Comments)}
                                            >
                                                <ListItemText primary="Comments History" />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};
