import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { CustomToolbar } from 'ui-component/grids/GridWithLevels/components';
import { styled } from '@mui/material';

export type ListProps = {
    loading: boolean;
    columns?: GridColDef[];
    rows?: readonly Record<string, any>[];
};

export const List = ({ loading, rows = [], columns = [] }: ListProps) => (
    <StripedDataGrid
        loading={loading}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        columns={columns}
        rows={rows}
        editMode="row"
        components={{
            Toolbar: CustomToolbar
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        disableRowSelectionOnClick
        pagination
        checkboxSelection
    />
);

export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200]
    },
    [`& .MuiDataGrid-row--editing`]: {
        color: '#616161 !important',
        '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
            color: '#9e9e9e !important'
        }
    },
    [`& .${gridClasses.row}`]: {
        '&.MuiDataGrid-row--editing': {
            '&:hover, &.Mui-hovered': {
                '& .MuiTypography-root': {
                    color: '#616161 !important'
                }
            }
        },
        '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], .MuiTypography-root': {
                color: '#FFF'
            },
            '@media (hover: none)': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#FFF'
            }
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], .MuiTypography-root': {
                color: '#FFF'
            },
            '&:hover, &.Mui-hovered': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#FFF',
                '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                    color: '#FFF'
                },
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: theme.palette.secondary.dark,
                    color: '#FFF'
                }
            }
        }
    }
}));
