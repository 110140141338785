import React, { useCallback } from 'react';
import { Download, Print as PrintIcon, Rotate90DegreesCcw, Save /* Share */, Search as SearchIcon, SearchOff } from '@mui/icons-material';
import { Divider, Grid, IconButton, Tooltip, useTheme } from '@mui/material';
import { RenderRotateProps, RotateProps } from '@react-pdf-viewer/rotate';
import { PrintProps, RenderPrintProps } from '@react-pdf-viewer/print';
import { RotateDirection } from '@react-pdf-viewer/core';
import { SearchPlugin } from '@react-pdf-viewer/search';

export interface RibbonProps {
    Rotate: (props: RotateProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    onSaveAnnotations?: () => void;
    onDownload?: () => void;
    Print?: (props: PrintProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    searchPluginInstance?: SearchPlugin;
    setShowSearchSidebard?: (value: boolean) => void;
    showSearchSidebard?: boolean;
    hasFile?: boolean;
}

export const Ribbon = ({
    Rotate,
    onSaveAnnotations,
    onDownload,
    Print,
    searchPluginInstance,
    setShowSearchSidebard,
    showSearchSidebard,
    hasFile
}: RibbonProps) => {
    const theme = useTheme();
    const handleClearHighlights = useCallback(() => {
        if (searchPluginInstance) {
            const { clearHighlights } = searchPluginInstance;
            clearHighlights();
        }
    }, [searchPluginInstance]);
    return (
        <Grid container justifyContent="space-between" sx={{ bgcolor: 'white', padding: '1rem 0' }}>
            {/* Searchbar */}
            {hasFile ? (
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Tooltip title={!showSearchSidebard ? 'Search' : 'Close Search Bar'}>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                if (showSearchSidebard) {
                                    handleClearHighlights();
                                }
                                setShowSearchSidebard?.(!showSearchSidebard);
                            }}
                        >
                            {!showSearchSidebard ? <SearchIcon /> : <SearchOff />}
                        </IconButton>
                    </Tooltip>
                </Grid>
            ) : (
                <Grid item xs="auto" />
            )}
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {/* <Tooltip title="Annotations">
                    <IconButton color="secondary">
                        <ContactSupport />
                    </IconButton>
                </Tooltip> */}
                {hasFile && (
                    <>
                        <Rotate direction={RotateDirection.Backward}>
                            {(props: RenderRotateProps) => (
                                <Tooltip title="Rotate">
                                    <IconButton onClick={props.onClick} color="secondary">
                                        <Rotate90DegreesCcw />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Rotate>
                        <Divider sx={{ borderColor: theme.palette.secondary.main }} orientation="vertical" variant="middle" />
                    </>
                )}
                {/* <Tooltip title="Share">
                        <IconButton color="secondary" onClick={onShare}>
                            <Share />
                        </IconButton>
                    </Tooltip> */}
                {!!onSaveAnnotations && (
                    <Tooltip title="Save Annotations" onClick={onSaveAnnotations}>
                        <IconButton color="secondary">
                            <Save />
                        </IconButton>
                    </Tooltip>
                )}
                {!!onDownload && (
                    <Tooltip title="Download">
                        <IconButton color="secondary" onClick={onDownload}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                )}
                {hasFile && Print && (
                    <Print>
                        {(props: RenderPrintProps) => (
                            <Tooltip title="Print">
                                <IconButton color="secondary" onClick={props.onClick}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Print>
                )}
            </Grid>
        </Grid>
    );
};

export default Ribbon;
