import { HighlightOff, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React, { ChangeEvent } from 'react';

export interface SearchBoxProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    showEndIcon?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
}

export const SearchBox = ({
    value,
    onChange,
    onClear,
    showEndIcon = true,
    fullWidth = false,
    placeholder = 'Find in Current Page'
}: SearchBoxProps) => (
    <OutlinedInput
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        sx={(theme) => ({
            width: fullWidth ? '100%' : '300px',
            borderRadius: '30px',
            backgroundColor: '#F5F6F7 !important',
            color: `${theme.palette.secondary.main} !important`,
            '&.Mui-focused': {
                backgroundColor: `${theme.palette.secondary.light} !important`,
                '& svg.MuiSvgIcon-root > path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            },
            '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                backgroundColor: `${theme.palette.secondary.light} !important`,
                color: `${theme.palette.secondary.main} !important`,
                '&::placeholder': { color: 'transparent !important' },
                '& svg': {
                    color: `${theme.palette.secondary.main} !important`,
                    opacity: 0.5
                }
            },
            height: '40px',
            '& > fieldset': {
                borderRadius: '30px',
                border: 'none !important'
            },
            '& input': {
                backgroundColor: '#F5F6F7 !important'
            }
        })}
        id="input-search-list-style1"
        placeholder={placeholder}
        startAdornment={
            <InputAdornment position="start">
                <Search />
            </InputAdornment>
        }
        endAdornment={
            showEndIcon ? (
                <InputAdornment position="end">
                    <IconButton onClick={() => onClear()}>
                        <HighlightOff />
                    </IconButton>
                </InputAdornment>
            ) : (
                <></>
            )
        }
        inputProps={{
            sx: (theme: any) => ({
                borderRadius: '30px',
                paddingLeft: '1rem !important'
            })
        }}
        size="small"
    />
);
