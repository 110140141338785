/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import { addResponseMessage, Widget } from 'react-chat-widget';
import value from 'assets/scss/_themes-vars.module.scss';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROPERTIES_PANEL } from 'graphql/queries/propertiesPanel';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ToggleButtonGroup
} from '@mui/material';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { QUERY_GET_PROBLEM_CODE } from 'graphql/queries/problemCode';
import { IGetDocumentProperties, IProblemCode, IRecordStatus } from 'views/DocumentViewer/types';
import { QUERY_FIND_RECORD_STATUS } from 'graphql/queries/recordStatus';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { IUser } from 'views/backoffice/users/types';
import EditPanelV2 from './EditPanel/v2';
import { IGetRecordFields } from 'ui-component/records/types';
import { QUERY_GET_RECORD_FIELDS } from 'graphql/queries/bills';
import { getAditionalFieldsObj, getAditionalFieldsHeaders } from './utils';
import { DetailsDialog, FullHistoryDialog } from '../Dialogs';

interface PropertiesPanelProps {
    recordType: string;
    recordId: string;
}
interface IForm {
    recordNumber: string;
    statusId: number | null;
    problemCodeId: number | null;
    approvedBy: number | null;
    recordDate: string | null;
    dueDate: string | null;
    poNumber: string;
    assignedTo: number | null;
}
/**
 * @deprecated Now should be using v2
 */
export const PropertiesPanelv1 = ({ recordId, recordType }: PropertiesPanelProps) => {
    const enum PanelTitle {
        Edit = 'Edit',
        LogReport = 'Log report'
    }
    const [hideForm, setHideForm] = useState(false);
    const [changePanel, setchangePanel] = useState<PanelTitle>(PanelTitle.Edit);
    const [title, setTitle] = useState('Properties Panel');
    const [showHistory, setShowHistory] = useState(false);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [detailsDialog, setDetailsDialog] = useState(false);
    const chatAvatar = 'https://www.w3schools.com/howto/img_avatar.png';

    const [form, setForm] = useState<IForm>({
        recordNumber: '',
        statusId: null,
        problemCodeId: null,
        approvedBy: null,
        // vendorID: 'Vendor ID',
        // vendorName: 'Exemplis',
        recordDate: null,
        dueDate: null,
        poNumber: '',
        assignedTo: null
        // invoiceTotal: '49932.00'
    });

    const { data: documentData } = useQuery<IGetDocumentProperties>(QUERY_GET_PROPERTIES_PANEL, {
        variables: {
            data: {
                id: Number(recordId),
                recordType: Number(recordType)
            }
        },
        fetchPolicy: 'no-cache'
    });
    const { data: headerData } = useQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
        variables: {
            recordType: Number(recordType)
        },
        fetchPolicy: 'no-cache'
    });
    const { data: problemCodeData } = useQuery<{ findProblemCodeByRecordType: IProblemCode[] }>(QUERY_GET_PROBLEM_CODE, {
        variables: {
            data: {
                recordType: Number(recordType)
            }
        },
        fetchPolicy: 'no-cache'
    });
    const { data: recordStatusData } = useQuery<{ findRecordStatusByRecordType: IRecordStatus[] }>(QUERY_FIND_RECORD_STATUS, {
        variables: {
            data: {
                recordType: Number(recordType)
            }
        },
        fetchPolicy: 'no-cache'
    });
    const { data: usersData } = useQuery<{ getSaasUsers: IUser[] }>(QUERY_GET_USERS);

    const problemCodeList = problemCodeData?.findProblemCodeByRecordType || [];
    const recordStatusList =
        recordStatusData?.findRecordStatusByRecordType?.filter((el) => Number(el.recordType.id) === Number(recordType)) || [];
    const usersList = usersData?.getSaasUsers || [];
    const recordData = documentData?.findRecordHeader[0] || null;
    const fieldList = useMemo(
        () => getAditionalFieldsHeaders(headerData?.getSaasRecordFieldsByRecordTypes) || null,
        [headerData?.getSaasRecordFieldsByRecordTypes]
    );

    const aditionalFields = useMemo(
        () => (fieldList && recordData?.additionalFields ? getAditionalFieldsObj(fieldList, recordData?.additionalFields) : {}),
        [fieldList, recordData?.additionalFields]
    );

    const setFormProperty = (propertyName: string, val: any) => {
        setForm({
            ...form,
            [propertyName]: val
        });
    };

    const toggleEditForm = () => {
        setHideForm((s) => !s);
    };

    function handleLogReport() {
        setTitle('Record History');
        setShowHistory(true);
        setchangePanel(PanelTitle.LogReport);
    }
    const resetLogReport = () => {
        setchangePanel(PanelTitle.Edit);
        setTitle('Properties Panel');
        setShowHistory(false);
    };

    const boxStyle = {
        backgroundColor: value.grey100,
        display: 'flex',
        padding: '19px 0px 0px 0px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    };

    const handleNewUserMessage = (newMessage: any) => {
        console.log(`¡new Message! ${newMessage} `);
        // recibe el mensaje a través de la API backend
    };

    const onReset = () => {
        setHideForm(false);
        // dispatch(
        //     openSnackbar({
        //         open: true,
        //         message: `Creation successful`,
        //         variant: 'alert',
        //         alert: {
        //             color: 'success'
        //         },
        //         close: true
        //     })
        // );
    };

    const parseRecordStatus = (recordStatus: string) =>
        recordStatusList.find(({ id }) => Number(id) === Number(recordStatus))?.name || 'No Data';

    const parseProblemCode = (recordProblemCode: string): string =>
        problemCodeList.find(({ id }) => Number(id) === Number(recordProblemCode))?.name || 'No Data';

    const parseUsers = (userId: string) => usersList.find(({ id }) => Number(id) === Number(userId))?.name || 'No Data';

    useEffect(() => {
        addResponseMessage('**Demo:** I think theres something wrong with the address');
        if (!recordData || !fieldList) return;
        setForm({
            recordNumber: String(recordData.recordNumber),
            statusId: Number(recordData.statusId?.id) || null,
            problemCodeId: Number(recordData.problemCodeId?.id) || null,
            approvedBy: Number(recordData.approvedBy?.id) || null,
            // vendorID: data.findRecordHeader,
            // vendorName: data.findRecordHeader,
            recordDate: recordData.recordDate,
            dueDate: recordData.dueDate,
            poNumber: recordData.poNumber,
            assignedTo: Number(recordData.assignedTo?.id) || null,
            ...(aditionalFields || {})
            // invoiceTotal: data.findRecordHeader
        });
    }, [aditionalFields, fieldList, recordData]);

    return (
        <div data-testid="properties-panel">
            <Box position="static" style={boxStyle}>
                <List>
                    <Typography variant="h4" component="div" color="#C10230" style={{ paddingLeft: 10 }}>
                        {title}
                    </Typography>
                    <span>
                        {showHistory && (
                            <Button
                                onClick={() => {
                                    setHistoryDialog(true);
                                }}
                                style={{ paddingTop: 0 }}
                            >
                                <ArrowBack style={{ color: '#C10230', width: 14 }} />
                                <Typography color="#C10230"> Show Full History</Typography>
                            </Button>
                        )}
                    </span>
                </List>
                <Box sx={{ flexGrow: 1 }}>
                    <ToggleButtonGroup
                        value={changePanel}
                        onChange={(_e, newMode: PanelTitle.Edit | PanelTitle.LogReport) => newMode && setchangePanel(newMode)}
                        sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end' }}
                    >
                        <Grid>
                            {changePanel === 'Log report' ? (
                                <IconButton
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onClick={resetLogReport}
                                    value="Log report"
                                    size="small"
                                    style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onClick={handleLogReport}
                                    value="Log report"
                                    size="small"
                                    style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}
                                >
                                    <AccessTimeIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid style={{ paddingLeft: 18, paddingBottom: 12, paddingRight: 8 }}>
                            <IconButton
                                value="Edit"
                                onClick={() => {
                                    toggleEditForm();
                                }}
                                data-testid="editButton"
                                size="small"
                                style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}
                            >
                                {hideForm ? <Close fontSize="small" /> : <EditIcon fontSize="small" />}
                            </IconButton>
                        </Grid>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {hideForm ? (
                <EditPanelV2
                    onReset={onReset}
                    form={form}
                    setFormProperty={setFormProperty}
                    recordStatusList={recordStatusList}
                    problemCodeList={problemCodeList}
                    usersList={usersList}
                    recordId={Number(recordId)}
                    setForm={setForm}
                    aditionalFieldHeader={fieldList}
                    aditionalFieldRawValues={recordData?.additionalFields || []}
                />
            ) : changePanel === 'Log report' ? (
                <List>
                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <ModeEditIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Updated"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <VisibilityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Viewed"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <FileDownloadIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Downloaded"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <ModeEditIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Updated"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <VisibilityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Viewed"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <FileDownloadIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Downloaded"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <ModeEditIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Updated"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>

                    <ListItemButton
                        onClick={() => {
                            setDetailsDialog(true);
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <VisibilityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItem>
                            <ListItemText
                                primary="Record Data Viewed"
                                secondary={
                                    <>
                                        <Typography variant="body2">Jhon Gomez</Typography>
                                        Dec 8, 2022 - 1:17pm
                                    </>
                                }
                            />
                        </ListItem>
                    </ListItemButton>
                </List>
            ) : (
                <Grid container maxWidth="md" data-testid="details-list">
                    <FormControl style={{ width: '98%', paddingLeft: 2 }} size="small">
                        <Grid style={{ paddingBottom: 10, paddingTop: 10 }}>
                            <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Record Number</Typography>
                            <Typography style={{ paddingTop: 18, paddingLeft: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                {form.recordNumber}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography style={{ padding: 10, fontWeight: 400, fontSize: '15px' }} color="#C10230">
                                Index Properties
                            </Typography>
                            <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Record Status</Typography>
                            <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                {parseRecordStatus(String(form.statusId))}
                            </Typography>
                        </Grid>
                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Problem Code</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {parseProblemCode(String(form.problemCodeId))}
                        </Typography>
                        {/* <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Vendor ID</Typography>
                            <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                {form.vendorID}
                            </Typography>
                            <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Vendor Name</Typography>
                            <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                {form.vendorName}
                            </Typography> */}
                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Record Date</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {form.recordDate ? format(new Date(form.recordDate), 'yyyy/MM/dd') : 'No Data'}
                        </Typography>

                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Assigned To</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {parseUsers(String(form.assignedTo) || '')}
                        </Typography>

                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Approved By</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {parseUsers(String(form.approvedBy) || '')}
                        </Typography>

                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>Due Date</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {form.dueDate ? format(new Date(form.dueDate), 'yyyy/MM/dd') : 'No Data'}
                        </Typography>
                        <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>PO Number</Typography>
                        <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                            {form.poNumber}
                        </Typography>
                        {Object.keys(aditionalFields || {}).map((key) => (
                            <>
                                <Typography style={{ padding: 10, fontWeight: 600, fontSize: '13px' }}>{key}</Typography>
                                <Typography style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181">
                                    {form[key as keyof IForm]}
                                </Typography>
                            </>
                        ))}
                    </FormControl>
                </Grid>
            )}

            {/* @ts-ignore */}
            <Widget
                data-testid="chatButton"
                handleNewUserMessage={handleNewUserMessage}
                title="Comments"
                subtitle=""
                emojis="false"
                profileAvatar={chatAvatar}
            />

            <FullHistoryDialog open={historyDialog} onClose={() => setHistoryDialog(false)} />
            <DetailsDialog open={detailsDialog} onClose={() => setDetailsDialog(false)} />
        </div>
    );
};

export default PropertiesPanelv1;
