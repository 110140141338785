import { Box, Paper } from '@mui/material';
import { ReactNode } from 'react';

export const LogReportContainer = ({ children }: { children: ReactNode }) => (
    <Box
        component={Paper}
        sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 12px)',
            p: '8px',
            my: '12px',
            bgcolor: theme.palette.primary[300]
        })}
    >
        {children}
    </Box>
);

export default LogReportContainer;
